import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import type { TestOnboardingCardParams, TestAlertActionError, TestAlertActionSuccess } from './types';
import { TestOnboardingCardsActionTypes } from './actionTypes';

const testCardSuccess = (): TestAlertActionSuccess =>
  actionCreator.createAction(TestOnboardingCardsActionTypes.TEST_SUCCESS);

const testCardError = ({ errorData }): TestAlertActionError =>
  actionCreator.createErrorAction(TestOnboardingCardsActionTypes.TEST_ERROR, errorData);

const testOnboardingCard = (slug: string, parameters: TestOnboardingCardParams) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.ONBOARDING.TEST,
    loadingAction: TestOnboardingCardsActionTypes.TEST_REQUEST,
    method: 'post',
    parameters,
    urlParameters: { slug },
    successBinding: testCardSuccess,
    errorBinding: testCardError,
  });

export const testAlertsActions = {
  testOnboardingCard,
};

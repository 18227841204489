import type { tFunction } from 'utils/translation';
import { RedeemFieldType, RedeemField, RedemptionRedeemedField } from 'model/indexTS';

const createRedeemField = (type: RedeemFieldType, t: tFunction): RedeemField => {
  const field = {
    required: false,
    label: t('adminCards:cardTemplates.redeemFields.message'),
    name: 'message',
    type,
  };

  if (type === RedeemFieldType.TEXTAREA) {
    field.label = t('adminCards:cardTemplates.redeemFields.longMessage');
  }
  return field;
};

const createRedeemFields = (t: tFunction): RedeemField[] => {
  return [createRedeemField(RedeemFieldType.TEXT, t), createRedeemField(RedeemFieldType.TEXTAREA, t)];
};

const createRedemptionRedeemedField = (redemptionField: Partial<RedemptionRedeemedField>): RedemptionRedeemedField => {
  return {
    required: false,
    label: 'Message',
    name: 'message',
    value: 'test',
    type: RedeemFieldType.TEXT,
    ...redemptionField,
  };
};

const createAnonymousRedeemedField = (): RedemptionRedeemedField => {
  return {
    value: true,
    type: RedeemFieldType.HIDDEN,
    name: 'anonymous',
    label: 'Anonymous',
  };
};

const createIgnorePowerRedeemedField = (): RedemptionRedeemedField => {
  return {
    value: true,
    type: RedeemFieldType.HIDDEN,
    name: 'ignorePower',
    label: 'Ignore power',
  };
};

export default {
  createRedemptionRedeemedField,
  createAnonymousRedeemedField,
  createIgnorePowerRedeemedField,
  createRedeemField,
  createRedeemFields,
};

import { Rarity } from '@streamloots/streamloots-types';
import { latinize } from 'utils/latinize';
import { PublicPageCardFilters, UserCard, PublicPageCardSelectableFilters } from 'model/indexTS';

function cardMatchString(card: UserCard, searchString: string): boolean {
  if (!searchString) {
    return true;
  }

  const { normalizedName, normalizedDescription } = card;

  return normalizedName.indexOf(searchString) > -1 || normalizedDescription.indexOf(searchString) > -1;
}

const filters = {
  [Rarity.COMMON]: (card: UserCard) => {
    return card.rarity === Rarity.COMMON;
  },
  [Rarity.RARE]: (card: UserCard) => {
    return card.rarity === Rarity.RARE;
  },
  [Rarity.EPIC]: (card: UserCard) => {
    return card.rarity === Rarity.EPIC;
  },
  [Rarity.LEGENDARY]: (card: UserCard) => {
    return card.rarity === Rarity.LEGENDARY;
  },
  [PublicPageCardSelectableFilters.NOT_ACHIEVED]: (card: UserCard) => {
    const count = card.count || {};
    return !count.total;
  },
  [PublicPageCardSelectableFilters.LIMITED]: (card: UserCard) => {
    return Boolean(card.dropLimited);
  },
  [PublicPageCardSelectableFilters.AVAILABLE]: (card: UserCard) => {
    const count = card.count || {};
    return count.redeemable > 0;
  },
};

export const filterCollectionCards = (
  cards: Array<UserCard>,
  filtersToApply: PublicPageCardFilters = {
    options: [],
    rarities: [],
  },
): Array<UserCard> => {
  const additionalFilters = filtersToApply.options || [];
  const rarityFilters = filtersToApply.rarities || [];
  const totalFilters = [...additionalFilters, ...rarityFilters];
  const searchString = latinize(filtersToApply.searchString || '');

  if (totalFilters.length === 0 && !searchString) {
    return cards;
  }

  const filtersFunctions = totalFilters.map(filter => {
    return filters[filter];
  });

  return cards.filter(card => {
    if (!cardMatchString(card, searchString)) {
      return false;
    }

    if (filtersFunctions.length === 0) {
      return true;
    }

    return filtersFunctions.some(filterFunction => {
      return filterFunction(card);
    });
  });
};

import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { TranslateInterface, withTranslation } from 'utils/translation';
import { InputNumber } from 'ui-library/form-elements';
import type { RenderOpenerDialogParams } from 'ui-library/dialog';
import { DialogOpener, DialogContent } from 'ui-library/dialog';
import Button, { ButtonIcon, ButtonRow } from 'ui-library/button';
import { DisenchantResourceSymbol } from 'components/disenchant-resources';
import theme from './destroy-confirmation-dialog.scss';

const classNames = ClassNames(theme);

interface DestroyConfirmationDialogProps extends TranslateInterface {
  availableCardsKey: string;
  confirmKey: string;
  disenchantingReward: number;
  labelKey: string;
  maxDestroyable: number;
  messageKey: string;
  onConfirmClick: (numCardsToDestroy: number) => void;
  renderButton: (params: RenderOpenerDialogParams) => React.ReactElement;
  title: string;
}

type OwnProps = Omit<DestroyConfirmationDialogProps, 't'>;

const DestroyConfirmationDialog = ({
  availableCardsKey,
  confirmKey,
  disenchantingReward,
  labelKey,
  maxDestroyable,
  messageKey,
  onConfirmClick,
  renderButton,
  t,
  title,
}: DestroyConfirmationDialogProps): JSX.Element => {
  const [numCardsToDestroy, setNumCardsToDestroy] = React.useState(1);

  return (
    <DialogOpener
      fullOnSmall
      wrapperClassName={classNames('destroy-dialog__wrapper')}
      renderOpener={renderButton}
      renderDialogBody={({ onClose }) => (
        <DialogContent title={title} onClose={onClose}>
          <div className={classNames('destroy-dialog__content')}>
            <p>{t(availableCardsKey, { count: maxDestroyable })}</p>
            <InputNumber
              label={t(labelKey)}
              value={numCardsToDestroy}
              onChange={value => setNumCardsToDestroy(value || 1)}
              min={1}
              max={maxDestroyable}
            />
            <p className="margin-bottom">
              {t(messageKey, {
                count: numCardsToDestroy,
                resourcesCount: disenchantingReward * numCardsToDestroy,
              })}
              <DisenchantResourceSymbol className={classNames('destroy-dialog--symbol')} />
            </p>
          </div>
          <ButtonRow>
            <Button label={t('common:cancel')} onClick={onClose} />
            <ButtonIcon
              label={t(confirmKey, { count: numCardsToDestroy })}
              onClick={() => {
                onConfirmClick(numCardsToDestroy);
                onClose();
                setNumCardsToDestroy(1);
              }}
              icon="fire"
              primary
            />
          </ButtonRow>
        </DialogContent>
      )}
    />
  );
};

export default withTranslation('collection', 'common')(DestroyConfirmationDialog) as React.FunctionComponent<OwnProps>;

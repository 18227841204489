import { createSelector } from 'reselect';
import { differenceInDays } from 'date-fns';
import { PublicPredefinedCard } from 'model/indexTS';
import { getSelectedFrame } from './normalizer';
import type {
  PredefinedCardsPublicState,
  PublicPredefinedCardsFetchedData,
  PublicPredefinedCardsFrameReceived,
} from './types';

const predefinedCardsSelector = (state): PredefinedCardsPublicState => {
  return state.publicPredefinedCards;
};

const predefinedCardsSelectorByKey = (state, componentKey: string): PublicPredefinedCardsFetchedData => {
  return predefinedCardsSelector(state)[componentKey] || {};
};

const card = createSelector(
  predefinedCardsSelectorByKey,
  (_state, _componentKey, id) => id,
  (predefinedCards, id): PublicPredefinedCard | null => {
    const foundCard = predefinedCards.predefinedCards?.find(predefinedCard => predefinedCard._id === id);

    return foundCard || null;
  },
);

const originals = createSelector(
  [predefinedCardsSelectorByKey],
  (predefinedCardsState): PublicPredefinedCardsFetchedData => {
    const { predefinedCards, filters: { frame = '' } = {} } = predefinedCardsState;

    return {
      ...predefinedCardsState,
      predefinedCards: predefinedCards?.map(predefinedCard => {
        const { frames, ...predefinedCardWithoutFrames } = predefinedCard;
        const selectedFrame = getSelectedFrame(frames, frame);
        return {
          frames,
          ...predefinedCardWithoutFrames,
          recentlyPublished: selectedFrame ? differenceInDays(Date.now(), selectedFrame.firstPublishedAt) < 15 : false,
        };
      }),
    };
  },
);

const frameById = createSelector(
  predefinedCardsSelectorByKey,
  (_state, _componentKey, frameId) => frameId,
  (predefinedCardsState, frameId: string): PublicPredefinedCardsFrameReceived | null => {
    const { frames } = predefinedCardsState;

    if (!Array.isArray(frames)) {
      return null;
    }

    return frames.find(frame => frame._id === frameId) || null;
  },
);

export const predefinedCardsPublicSelectors = {
  card,
  originals,
  frameById,
};

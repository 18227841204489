import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { RedeemCard, TestRedemption } from './types';
import { redemptionActions } from './actions';

export interface UsePagePayoutMethodActions {
  redeemCard: RedeemCard;
  testRedemption: TestRedemption;
}

export const useRedemptionActions = (): UsePagePayoutMethodActions => {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      redeemCard: (collectionId, setCardId, redeemFields, setId) =>
        dispatch(redemptionActions.redeemCard(collectionId, setCardId, redeemFields, setId)),
      testRedemption: (setId, cardId, redeemFields) =>
        dispatch(redemptionActions.testRedemption(setId, cardId, redeemFields)),
    }),
    [dispatch],
  );
};

import Tooltip from 'ui-library/tooltip';
import { useSubscriptionCheckoutOpener } from 'scenes/subscription-checkout'; // TODO mover dependencias
import { Badge } from 'ui-library/badge';
import { usePageSelectors } from 'services/page';
import { useTranslation } from 'react-i18next';

export const OnlySubsBadge = () => {
  const { t } = useTranslation('common');
  const {
    page: { slug },
  } = usePageSelectors();
  const { isSubscribed, openSubscriptionCheckout } = useSubscriptionCheckoutOpener();
  const handleClick = () => {
    if (isSubscribed) {
      return;
    }
    openSubscriptionCheckout('play card as anonymous');
  };

  return (
    <Tooltip
      tooltip={t(isSubscribed ? 'common:onlyForSubs' : 'common:subscribeToUse', { slug })}
      placement="top"
      trigger={isSubscribed ? ['hover', 'click'] : ['hover']}
    >
      <span className="inline-flex cursor-pointer">
        <Badge label={t('common:subscribers')} color="electric-blue" onClick={handleClick} />
      </span>
    </Tooltip>
  );
};

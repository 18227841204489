import { useState, useRef, useEffect, FunctionComponent } from 'react';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import ClassNames from '@streamloots/classnames';
import type { UserCard, UserSet } from 'model/indexTS';
import { withTranslation, TranslateInterface } from 'utils/translation';
import trackEventManager from 'utils/event-manager';
import type { CraftCards } from 'services/crafting-mode';
import { craftingModeActions, craftingModeSelectors } from 'services/crafting-mode';
import { userSetsSelectors } from 'services/user-sets';
import { pageSelectors } from 'services/page';
import { useReactAlert } from 'components/react-alert-provider';
import BuyLootPacksPicker from 'components/buy-loot-packs-picker';
import { CanCreateCardDisenchantButton } from './CanCreateCardDisenchantButton';
import theme from './create-card-or-buy.scss';

const classNames = ClassNames(theme);

interface OwnProps {
  card: UserCard;
  redeemableCards: number;
  cardCanBeCreated: boolean;
}

interface MapDispatchToProps {
  craftCards: CraftCards;
}

type MapStateToProps = {
  dustImageUrl: string;
  dustBalance: number;
  userSet: UserSet | null;
};

interface ConnectedCreateCardDisenchantButtonProps
  extends OwnProps,
    MapStateToProps,
    TranslateInterface,
    MapDispatchToProps {}

const ConnectedCreateCardDisenchantButton = ({
  userSet,
  craftCards,
  card,
  dustBalance,
  redeemableCards,
  dustImageUrl,
  cardCanBeCreated,
  t,
}: ConnectedCreateCardDisenchantButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showSuccess, showError } = useReactAlert();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const createCard = () => {
    if (!userSet?.collectionId) {
      return;
    }
    setIsLoading(true);

    const { collectionId, _id: setId } = userSet;

    craftCards(card._id, 1, collectionId, setId).then(response => {
      if (isMounted.current) {
        setIsLoading(false);
      }

      if (response.error) {
        showError(response.payload.message);
        return;
      }

      trackEventManager.cardCraftingEvent({
        card,
        count: 1,
        mode: 'crafting',
        setId,
        source: 'Card information',
      });

      showSuccess(t('card:cardCraftedSuccess'));
    });
  };

  if (!userSet) {
    return null;
  }

  if (redeemableCards === 0 && cardCanBeCreated) {
    return (
      <CanCreateCardDisenchantButton
        createCard={createCard}
        dustBalance={dustBalance}
        craftingCost={card.craftingCost}
        dustImageUrl={dustImageUrl}
        isLoading={isLoading}
      />
    );
  }

  if (redeemableCards === 0) {
    return (
      <div className={classNames('create-card-buy-button')}>
        <p>{t('card:noCardsAvailableBuy')}</p>
        <BuyLootPacksPicker buttonId="redeem-form-button" icon="treasure" />
      </div>
    );
  }

  return null;
};

const mapStateToProps = (state): MapStateToProps => {
  return {
    dustBalance: craftingModeSelectors.dustBalance(state),
    dustImageUrl: pageSelectors.dustImageUrl(state),
    userSet: userSetsSelectors.selectedUserSet(state),
  };
};

export default compose<FunctionComponent<OwnProps>>(
  withTranslation('card'),
  connect(mapStateToProps, {
    craftCards: craftingModeActions.craftCards,
  }),
)(ConnectedCreateCardDisenchantButton);

import { useState } from 'react';
import Countdown from 'react-countdown-now';
import CardStatusIcon from '../card-status-icon';

export interface CountdownWithIconProps {
  date?: Date | string | number;
  label: string;
  type: keyof typeof CardStatusIcon.TYPES;
  tooltip?: string;
  onComplete: () => void;
}

export const CountdownWithIcon = ({ date, label, type, tooltip, onComplete }: CountdownWithIconProps) => {
  const [completed, setCompleted] = useState(Boolean(date && new Date(date).getTime() < new Date().getTime()));

  if (completed) {
    return null;
  }

  return (
    <CardStatusIcon tooltip={tooltip} type={type} iconPosition={CardStatusIcon.ICON_POSITION.RIGHT}>
      <span>
        {label}{' '}
        {date && (
          <Countdown
            date={date}
            onComplete={() => {
              onComplete();
              setCompleted(true);
            }}
          />
        )}
      </span>
    </CardStatusIcon>
  );
};

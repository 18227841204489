import { useState, useEffect } from 'react';
import ClassNames from '@streamloots/classnames';
import { isBefore } from 'date-fns';
import type { UserCard, UserCooldownStatus, Power, PublicPredefinedCard, UserSet } from 'model/indexTS';
import { useTranslation } from 'utils/translation';
import { cooldownFormatter } from 'services/cooldown';
import { FixedCloseButton } from 'ui-library/fixed-close-button';
import useMedia from 'hooks/use-media';
import { USE_MEDIA_BREAKPOINTS } from 'constants/stylesBreakpoints';
import { cardImageUrlGetter } from 'components/card';
import { Languages } from '@streamloots/streamloots-types';
import { isOfType } from 'types/indexTS';
import { OwnerCard } from 'model/indexTS';
import { ButtonIcon } from 'ui-library/button';
import { usePowerSelectors } from 'services/alert';
import eventTracker from 'utils/event-manager';
import { DestroyCardButton } from './components/destroy-button-dialog';
import { RedeemForm } from './components/redeem-form';
import { CardInformationImage } from './components/card-information-image';
import ConnectedCanCreateCardDisenchantButton from './components/create-card-or-buy';
import { CollectionPaused } from './CollectionPaused';
import { CooldownCountdown } from './CooldownCountdown';
import { CardInformationStatus } from './CardInformationStatus';
import EffectsSelector from './EffectsSelector';
import theme from './card-information.scss';
import { CardInformationMobileCTA } from './CardInformationMobileCTA';

const classNames = ClassNames(theme);

const canCreateCard = (card: UserCard, dustBalance?: number): boolean => {
  const { dropLimited, craftingCost, dropLimitRemaining, craftable } = card;

  if (!dustBalance) {
    return false;
  }

  if (dropLimited && dropLimitRemaining === 0) {
    return false;
  }

  return craftable && dustBalance >= craftingCost;
};

const getDescriptionDetailed = (description?: string, descriptionDetailed?: string): string | null => {
  if (descriptionDetailed && description && description.trim() !== descriptionDetailed.trim()) {
    return descriptionDetailed;
  }

  return null;
};

type TypeCards = UserCard | PublicPredefinedCard | OwnerCard;

export interface CardInformationProps {
  card: TypeCards;
  onClose: () => void;
  userCooldown: UserCooldownStatus | undefined;
  isPreview?: boolean;
  isOwner: boolean;
  onClickEffectSelector?: (card: TypeCards) => void;
  onDestroy: (count: number) => void;
  alertEffect?: Power | null;
  isPredefinedCard?: boolean;
  language: Languages;
  userSet: UserSet | null;
  isUserAuthenticated?: boolean;
  dustBalance?: number;
}

const getUserCardDependantInfo = (
  card: UserCard | PublicPredefinedCard | OwnerCard,
): Partial<Pick<UserCard, 'obtainable' | 'dropLimitRemaining' | 'count' | 'disenchantingReward' | 'craftable'>> => {
  if (isOfType<UserCard>(card, 'count')) {
    const { obtainable, dropLimitRemaining, count, disenchantingReward, craftable } = card;
    return {
      dropLimitRemaining,
      obtainable,
      count,
      disenchantingReward,
      craftable,
    };
  }

  if (isOfType<OwnerCard>(card, 'status')) {
    const { obtainable, dropLimitRemaining, disenchantingReward, craftable } = card;
    return {
      dropLimitRemaining,
      obtainable,
      disenchantingReward,
      craftable,
    };
  }

  return {};
};

const CardInformation = ({
  card,
  userCooldown,
  isPreview,
  onClose,
  onClickEffectSelector,
  onDestroy,
  alertEffect,
  isPredefinedCard,
  language,
  userSet,
  isUserAuthenticated,
  dustBalance,
}: CardInformationProps): JSX.Element => {
  const { t } = useTranslation('card');
  const { activePowers } = usePowerSelectors();
  const [showExtraInfoInMobile, setShowExtraInfoInMobile] = useState(false);
  const {
    name: title,
    dropLimit: totalLimit,
    dropLimited: limited,
    fragmented: isFragmented,
    fragments: totalFragments,

    redemptionLimit,
    rarity,
    paid,
  } = card;
  const {
    dropLimitRemaining: cardsLimitedRemaining,
    obtainable: isObtainable = false,
    count: { availableFragments, redeemable: redeemableCards } = {
      availableFragments: 0,
      redeemable: 0,
    },
    disenchantingReward,
    craftable = false,
  } = getUserCardDependantInfo(card);

  const image = cardImageUrlGetter.getCardImageUrl(card, null, 250);
  const isMobile = useMedia(USE_MEDIA_BREAKPOINTS.maxWidthString.small);

  const cooldownTimeStatus = card.redemptionLimit
    ? cooldownFormatter.formatTime(card.redemptionLimit.configuration.timeFrameSeconds)
    : undefined;

  const cooldownTime = cooldownFormatter.getCooldownTime(redemptionLimit, userCooldown, rarity);
  const isCooldownActive = Boolean(cooldownTime && isBefore(Date.now(), cooldownTime));

  const [isCooldownActivated, setCooldownActivated] = useState(isCooldownActive);

  const showRedeemedForm = isPreview || isPredefinedCard || (redeemableCards > 0 && !userSet?.paused);
  const showDestroy =
    !isPreview &&
    !isPredefinedCard &&
    userSet != null &&
    userSet.craftableCards &&
    (redeemableCards > 0 || availableFragments > 0);

  const showPhoneCTA = !showRedeemedForm && !showDestroy;

  const cardCanBeCreated = !isPreview ? canCreateCard(card as UserCard, dustBalance) : false;

  useEffect(() => {
    setCooldownActivated(isCooldownActive);
  }, [redemptionLimit, userCooldown, rarity, isCooldownActive]);

  function renderEffectsSelectorComponent() {
    if (isPreview || !isUserAuthenticated || !onClickEffectSelector || !activePowers?.length) {
      return null;
    }

    return (
      <EffectsSelector
        card={card}
        onClose={onClose}
        onClickEffectSelector={onClickEffectSelector}
        alertEffect={alertEffect}
        language={language}
      />
    );
  }

  const largeDescription = getDescriptionDetailed(card.description, card.descriptionDetailed);

  return (
    <div className={classNames('cardinfo__content')}>
      <FixedCloseButton onClose={onClose} />
      {showPhoneCTA && !cardCanBeCreated && <CardInformationMobileCTA />}
      <div className={classNames('cardinfo__main')}>
        <div className={classNames('cardinfo__cardarea')}>
          {isMobile && renderEffectsSelectorComponent()}
          <div className={classNames('cardinfo__image-wrapper')}>
            <CardInformationImage image={image} title={title} isPreview={isPreview} />
          </div>
          {showDestroy && !isMobile && (
            <DestroyCardButton
              onDestroy={onDestroy}
              isMobile={isMobile}
              maxDestroyable={card.fragmented ? availableFragments : redeemableCards}
              fragmented={card.fragmented}
              disenchantingReward={disenchantingReward as number}
            />
          )}
        </div>
        <div className={classNames('cardinfo__infoarea')}>
          {!isMobile && renderEffectsSelectorComponent()}
          <h2 className={classNames('cardinfo__title')}>{title}</h2>
          <p>{card.description}</p>
          <ButtonIcon
            asLink
            className={classNames({
              'card-extra-info-opener': true,
              'card-extra-info-opener--open': showExtraInfoInMobile,
            })}
            icon="chevron-down"
            onClick={() => {
              setShowExtraInfoInMobile(!showExtraInfoInMobile);

              if (showExtraInfoInMobile) {
                eventTracker.trackEvent('card_clicked', { button: 'Show mobile extra info' });
              }
            }}
          >
            {t('card:showMoreInfo')}
          </ButtonIcon>
          <div
            className={classNames({
              'card-extra-info': true,
              'card-extra-info--open': showExtraInfoInMobile,
            })}
          >
            {largeDescription && <p className={classNames('cardinfo__action')}>{largeDescription}</p>}
            <CardInformationStatus
              redeemableCards={redeemableCards}
              cooldownTime={cooldownTimeStatus}
              limited={limited}
              totalLimit={totalLimit}
              availableFragments={availableFragments}
              totalFragments={totalFragments}
              cardsLimitedRemaining={cardsLimitedRemaining}
              isObtainable={isObtainable}
              isFragmented={isFragmented}
              rarity={rarity}
              craftable={craftable}
              isPreview={isPreview}
              paid={paid}
            />
          </div>
          {showRedeemedForm && (
            <RedeemForm
              card={card as UserCard}
              isPreview={isPreview}
              isCooldownActivated={isCooldownActivated}
              isFragmented={isFragmented}
              isPredefinedCard={isPredefinedCard}
              onDestroy={onDestroy}
              isMobile={isMobile}
              showDestroy={showDestroy}
              disenchantingReward={disenchantingReward}
            />
          )}
          <div className={classNames('cardinfo__actions')}>
            {!showRedeemedForm && (
              <ConnectedCanCreateCardDisenchantButton
                card={card as UserCard}
                redeemableCards={redeemableCards}
                cardCanBeCreated={cardCanBeCreated}
              />
            )}
            {!isPreview && (
              <>
                <CollectionPaused />
                <CooldownCountdown
                  cooldownDate={cooldownTime}
                  active={isCooldownActive}
                  onComplete={() => setCooldownActivated(false)}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardInformation;

import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { TestOnboardingCard } from './types';
import { testAlertsActions } from './actions';

export interface UsePagePayoutMethodActions {
  testCard: TestOnboardingCard;
}

export const useTestAlertsActions = (): UsePagePayoutMethodActions => {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      testCard: (slug, parameters) => dispatch(testAlertsActions.testOnboardingCard(slug, parameters)),
    }),
    [dispatch],
  );
};

import ClassNames from '@streamloots/classnames';
import { withTranslation, TranslateInterface } from 'utils/translation';
import trackEventManager from 'utils/event-manager';
import { ButtonIcon } from 'ui-library/button';
import { OwnerCard, Power, PublicPredefinedCard, UserCard } from 'model/indexTS';
import { useEffect } from 'react';
import { usePowerActions, usePowerSelectors } from 'services/alert';
import theme from './card-information.scss';

const classNames = ClassNames(theme);

type TypeCards = UserCard | PublicPredefinedCard | OwnerCard;

interface EffectsSelectorProps extends TranslateInterface {
  card: TypeCards;
  onClose: () => void;
  onClickEffectSelector: (card: TypeCards) => void;
  alertEffect?: Power | null;
  language: string;
}

function EffectsSelector(props: EffectsSelectorProps): JSX.Element | null {
  const { fetchPowers } = usePowerActions();
  const { powers, activePowers } = usePowerSelectors();
  const { card, onClose, onClickEffectSelector, alertEffect, language, t } = props;

  useEffect(() => {
    if (powers !== undefined) {
      return;
    }
    fetchPowers();
  }, [fetchPowers, powers]);

  const alertEffectRarity = alertEffect?.rarity?.toLowerCase() ?? '';

  if (activePowers.length <= 0) {
    return null;
  }
  return (
    <div className={classNames('cardinfo__effect-selector')}>
      <ButtonIcon
        icon="flash"
        size="sm"
        onClick={() => {
          onClose();
          onClickEffectSelector(card);
        }}
        label={t('selectEffect.button')}
      />

      <span
        className={classNames({
          'rarity': true,
          'rarity-effect': true,
          [alertEffectRarity]: alertEffectRarity,
        })}
      >
        {alertEffect?.locales &&
          t('selectEffect.activeEffect', {
            alertEffectName: alertEffect?.locales.title[language],
          })}
      </span>
    </div>
  );
}

export default withTranslation('powers')(EffectsSelector);

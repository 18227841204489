import { RedeemFieldType } from 'model/indexTS';
import { rules, Input } from 'ui-library/forms';

const maxRegularInputLength = rules.maxLength({ max: 70 });
const maxTextareaLength = rules.maxLength({ max: 200 });

interface RedeemedFieldInputProps {
  type: string;
  required?: boolean;
  name: string;
  label: string;
}

export const RedeemedFieldInput = ({ type, required, name, label }: RedeemedFieldInputProps): JSX.Element => {
  const isTextArea = type === RedeemFieldType.TEXTAREA;
  const validate = [isTextArea ? maxTextareaLength : maxRegularInputLength];

  if (required) {
    validate.push(rules.required);
  }

  return (
    <Input
      placeholder="bla bla bla"
      id={`redeem-${name}`}
      label={label}
      name={name}
      required={required}
      validate={validate}
      maxLength={isTextArea ? 200 : 70}
    />
  );
};

import { useEffect, FunctionComponent } from 'react';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import {
  Power,
  UserCard,
  UserCooldownStatus,
  SetBasicInfo,
  PublicPredefinedCard,
  OwnerCard,
  UserSet,
} from 'model/indexTS';
import trackEventManager from 'utils/event-manager';
import { withTranslation, TranslateInterface } from 'utils/translation';
import { craftingModeActions, craftingModeSelectors } from 'services/crafting-mode';
import type { DisenchantCards } from 'services/crafting-mode';
import { userSetCardsSelectors } from 'services/user-set-cards';
import { userSetLootsSelectors } from 'services/user-set-loots';
import { userSetsSelectors } from 'services/user-sets';
import { userSelectors } from 'services/user';
import { setCardsSelectors } from 'services/set-cards';
import { setsSelectors } from 'services/sets';
import { pageSelectors } from 'services/page';
import { cultureSelectors } from 'services/culture';
import { authSelectors } from 'services/auth';
import { predefinedCardsPublicSelectors } from 'services/predefined-cards-public';
import { cooldownSelectors } from 'services/cooldown';
import type { WithAlertProviderProps } from 'components/react-alert-provider';
import { useReactAlert, withAlertProvider } from 'components/react-alert-provider';
import { Languages } from '@streamloots/streamloots-types';
import CardInformation from './CardInformation';

type TypeCards = UserCard | PublicPredefinedCard | OwnerCard;
interface OwnProps {
  cardId: string;
  isPreview?: boolean;
  isPredefinedCard?: boolean;
  onClose: () => void;
  onClickEffectSelector?: (card: TypeCards) => void;
  openFromSource?: 'Public collection' | 'Open packs' | 'Direct link';
}

interface MapStateToProps {
  isCollectionPaused?: boolean;
  card: UserCard | PublicPredefinedCard | null | OwnerCard;
  isOwner: boolean;
  alertEffect?: Power | null;
  language: Languages;
  setId: string;
  collectionId: string;
  userSet: UserSet | null;
  dustBalance: number;
  isUserAuthenticated: boolean;
  userCooldown?: UserCooldownStatus;
}

interface MapDispatchToProps {
  destroyCards: DisenchantCards;
}

interface ConnectedCardInformationProps
  extends WithAlertProviderProps,
    MapDispatchToProps,
    MapStateToProps,
    TranslateInterface,
    OwnProps {}

const ConnectedCardInformation = ({
  card,
  userCooldown,
  isPreview,
  isOwner,
  onClose,
  onClickEffectSelector,
  alertEffect,
  isPredefinedCard,
  isUserAuthenticated,
  language,
  destroyCards,
  setId,
  collectionId,
  userSet,
  openFromSource,
  dustBalance,
  t,
}: ConnectedCardInformationProps) => {
  const { showSuccess, showError } = useReactAlert();
  useEffect(() => {
    if (!card || isPreview || !openFromSource) {
      return;
    }

    trackEventManager.cardInformationOpened({
      card: card as UserCard,
      setId,
      source: openFromSource,
    });
  }, [card, isPreview, openFromSource, setId]);

  if (!card) {
    return null;
  }

  const getTranslationKey = k => (card.fragmented ? `destroy.fragment.${k}` : `destroy.card.${k}`);

  const handleDestroy = (numCardsToDestroy = 1) => {
    destroyCards(card._id, numCardsToDestroy, collectionId, setId).then(response => {
      if (response.error) {
        const errorMessage = t(getTranslationKey('error'));
        showError(`${errorMessage}${response.error}`);
        return;
      }

      trackEventManager.cardCraftingEvent({
        card: card as UserCard,
        count: numCardsToDestroy,
        mode: 'disenchanting',
        setId,
        source: 'Card information',
      });

      showSuccess(t(getTranslationKey('success')));
    });
  };

  return (
    <CardInformation
      isPreview={isPreview}
      userCooldown={userCooldown}
      onClose={onClose}
      card={card}
      isOwner={isOwner}
      onClickEffectSelector={onClickEffectSelector}
      onDestroy={handleDestroy}
      alertEffect={alertEffect}
      isPredefinedCard={isPredefinedCard}
      language={language}
      userSet={userSet}
      isUserAuthenticated={isUserAuthenticated}
      dustBalance={dustBalance}
    />
  );
};

const getSetId = ({ isPreview, isPredefinedCard, state }) => {
  if (isPredefinedCard) {
    return '';
  }

  if (isPreview) {
    return setsSelectors.setId(state);
  }

  return userSetsSelectors.setId(state);
};

const getCard = ({ isPreview, setId, cardId, isPredefinedCard, state }) => {
  if (isPredefinedCard) {
    return predefinedCardsPublicSelectors.card(state, 'createFromTemplate', cardId);
  }

  if (isPreview) {
    return setCardsSelectors.card(state, setId, cardId);
  }

  return userSetCardsSelectors.card(state, setId, cardId);
};

const mapStateToProps = (state, props): MapStateToProps => {
  const { isPreview, cardId, isPredefinedCard } = props;
  const setId = getSetId({ isPreview, isPredefinedCard, state });
  const card = getCard({
    isPreview,
    cardId,
    state,
    setId,
    isPredefinedCard,
  });
  const language = cultureSelectors.language(state);
  return {
    card,
    isOwner: pageSelectors.isOwner(state),
    alertEffect: userSelectors.alertEffect(state),
    language,
    setId,
    collectionId: userSetLootsSelectors.collectionId(state, setId),
    userSet: userSetsSelectors.userSet(state, setId),
    isUserAuthenticated: authSelectors.isUserAuthenticated(state),
    dustBalance: craftingModeSelectors.dustBalance(state),
    userCooldown: cooldownSelectors.userCooldownStatus(state),
  };
};

export default compose<FunctionComponent<OwnProps>>(
  withAlertProvider,
  connect(mapStateToProps, {
    destroyCards: craftingModeActions.disenchantCards,
  }),
  withTranslation('collection'),
)(ConnectedCardInformation);

import type { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { withTranslation, TranslateInterface } from 'utils/translation';
import { alertProvidersSelectors } from 'services/alert-providers';
import { ButtonIcon } from 'ui-library/button';

interface MapStateToProps {
  alertWidgetUrl: string;
}

interface OpenAlertButtonProps extends TranslateInterface, MapStateToProps {}

const OpenAlertButton = ({ alertWidgetUrl, t }: OpenAlertButtonProps): JSX.Element | null => {
  if (!alertWidgetUrl) {
    return null;
  }

  return (
    <ButtonIcon
      asLink
      onClick={() => {
        const alertUrlWithBg = `${alertWidgetUrl}?bg=green`;
        window.open(alertUrlWithBg, 'Streamloots Alerts', 'height=400,width=700');
      }}
      icon="open"
      label={t('card:openAlert')}
    />
  );
};

const mapStateToProps = state => {
  return {
    alertWidgetUrl: alertProvidersSelectors.alertWidgetUrl(state),
  };
};

export default compose<FunctionComponent>(connect(mapStateToProps), withTranslation('card'))(OpenAlertButton);

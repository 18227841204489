import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import Icon from 'ui-library/icons';
import { CARD_SPECIAL_CONFIGURATION_ICONS } from 'components/card';
import TooltipHelpIndicatorWrapper from 'components/help-tooltip';
import theme from '../../card-information.scss';

const classNames = ClassNames(theme);

const CARD_STATUS_ICON_TYPES = {
  COOLDOWN: 'COOLDOWN',
  NEGATIVE_COOLDOWN: 'NEGATIVE_COOLDOWN',
  LIMITED: 'LIMITED',
  PAUSED: 'PAUSED',
  FRAGMENTED: 'FRAGMENTED',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  RARITY: 'RARITY',
  REDEEMABLE: 'REDEEMABLE',
  CRAFTABLE: 'CRAFTABLE',
  PAID: 'PAID',
} as const;

const CARD_STATUS_ICON_POSITION = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
} as const;

type CardStatusIconProps = {
  children: React.ReactNode;
  type: keyof typeof CARD_STATUS_ICON_TYPES;
  iconPosition?: keyof typeof CARD_STATUS_ICON_POSITION;
  tooltip?: string;
  redeemableCards?: number;
};

const CardStatusIcon = ({
  iconPosition = CARD_STATUS_ICON_POSITION.LEFT,
  children,
  type,
  redeemableCards,
  tooltip,
}: CardStatusIconProps) => {
  const classNamesIconPosition = classNames({
    'cardinfo__status': true,
    'cardinfo__status--right': iconPosition === CARD_STATUS_ICON_POSITION.RIGHT,
  });
  const classNamesIconBackground = classNames({
    'cardinfo__status__icon': true,
    'cardinfo__status__icon--cooldown':
      type === CARD_STATUS_ICON_TYPES.COOLDOWN || type === CARD_STATUS_ICON_TYPES.RARITY,
    'cardinfo__status__icon--cooldown--negative': type === CARD_STATUS_ICON_TYPES.NEGATIVE_COOLDOWN,
    'cardinfo__status__icon--paused': type === CARD_STATUS_ICON_TYPES.PAUSED,
    'cardinfo__status__icon--limited': type === CARD_STATUS_ICON_TYPES.LIMITED,
    'cardinfo__status__icon--notinchests': type === CARD_STATUS_ICON_TYPES.NOT_AVAILABLE,
    'cardinfo__status__icon--fragmented': type === CARD_STATUS_ICON_TYPES.FRAGMENTED,
    'cardinfo__status__icon--nocards': type === CARD_STATUS_ICON_TYPES.REDEEMABLE && redeemableCards === 0,
    'cardinfo__status__icon--redeemable':
      type === CARD_STATUS_ICON_TYPES.REDEEMABLE && redeemableCards && redeemableCards > 0,
    'cardinfo__status__icon--craftable': type === CARD_STATUS_ICON_TYPES.CRAFTABLE,
    'cardinfo__status__icon--paid': type === CARD_STATUS_ICON_TYPES.PAID,
  });

  const icons: Record<keyof typeof CARD_STATUS_ICON_TYPES, string> = {
    [CARD_STATUS_ICON_TYPES.COOLDOWN]: 'timelapse',
    [CARD_STATUS_ICON_TYPES.NEGATIVE_COOLDOWN]: 'timelapse',
    [CARD_STATUS_ICON_TYPES.LIMITED]: CARD_SPECIAL_CONFIGURATION_ICONS.LIMITED,
    [CARD_STATUS_ICON_TYPES.NOT_AVAILABLE]: 'treasure',
    [CARD_STATUS_ICON_TYPES.PAUSED]: 'pause-circle',
    [CARD_STATUS_ICON_TYPES.FRAGMENTED]: CARD_SPECIAL_CONFIGURATION_ICONS.FRAGMENTED,
    [CARD_STATUS_ICON_TYPES.RARITY]: 'card-common',
    [CARD_STATUS_ICON_TYPES.REDEEMABLE]: 'card',
    [CARD_STATUS_ICON_TYPES.CRAFTABLE]: 'diamond',
    [CARD_STATUS_ICON_TYPES.PAID]: 'payment',
  };

  const icon = icons[type];

  return (
    <div className={classNamesIconPosition}>
      {iconPosition === CARD_STATUS_ICON_POSITION.RIGHT && children}
      <div className={classNamesIconBackground}>
        {tooltip ? (
          <TooltipHelpIndicatorWrapper placement="top" tooltip={tooltip}>
            <span data-testid={`card-status-icon-${type.toLowerCase()}`}>
              <Icon id={icon} />
            </span>
          </TooltipHelpIndicatorWrapper>
        ) : (
          <Icon id={icon} />
        )}
      </div>
      {iconPosition === CARD_STATUS_ICON_POSITION.LEFT && children}
    </div>
  );
};

CardStatusIcon.TYPES = CARD_STATUS_ICON_TYPES;
CardStatusIcon.ICON_POSITION = CARD_STATUS_ICON_POSITION;

export default CardStatusIcon;

import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { withTranslation, TranslateInterface } from 'utils/translation';
import Button from 'ui-library/button';
import Icon from 'ui-library/icons';
import theme from './create-card-or-buy.scss';

const classNames = ClassNames(theme);

interface CanCreateCardDisenchantButtonOwnProps {
  createCard: () => void;
  craftingCost: number;
  dustBalance: number;
  dustImageUrl: string;
  isLoading: boolean;
}

interface CanCreateCardDisenchantButtonProps extends CanCreateCardDisenchantButtonOwnProps, TranslateInterface {}

const CanCreateCardDisenchantButtonInternal = ({
  createCard,
  craftingCost,
  dustBalance,
  dustImageUrl,
  isLoading,
  t,
}: CanCreateCardDisenchantButtonProps) => {
  return (
    <React.Fragment>
      <p>{t('disenchantCardNotAvailable')}</p>
      <div className={classNames('create-wrapper')}>
        <Button withInfo onClick={createCard} disabled={isLoading}>
          <Icon id="diamond" className={classNames('margin-right-s font-big')} />
          {t('createCardDisenchant')}
          <div>
            <img src={dustImageUrl} alt="" />
            {craftingCost}
          </div>
        </Button>
      </div>

      <p className="align-right">
        {t('dustBalanceText')} <img alt="" src={dustImageUrl} width="18px" className={classNames('margin-right-s')} />
        {dustBalance}
      </p>
    </React.Fragment>
  );
};

export const CanCreateCardDisenchantButton = withTranslation('card')(CanCreateCardDisenchantButtonInternal);

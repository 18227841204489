import { useTranslation } from 'utils/translation';
import { useDataConciliatorActions } from 'services/data-conciliator';
import { useUserSetsSelectors } from 'services/user-sets';
import CardStatusIcon from './components/card-status-icon';
import { CountdownWithIcon } from './components/countdown-with-icon';

export const CollectionPaused = () => {
  const { t } = useTranslation('card');
  const { selectedUserSet } = useUserSetsSelectors();
  const { invalidateUserSets } = useDataConciliatorActions();

  if (!selectedUserSet?.paused) {
    return null;
  }

  const { resumeAt, pausedReason } = selectedUserSet;

  return (
    <CountdownWithIcon
      date={resumeAt}
      tooltip={pausedReason}
      label={t('card:pausedCollection')}
      type={CardStatusIcon.TYPES.PAUSED}
      onComplete={invalidateUserSets}
    />
  );
};

import type { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import ClassNames from '@streamloots/classnames';
import BuyLootPacksPicker from 'components/buy-loot-packs-picker';
import { USE_MEDIA_BREAKPOINTS } from 'constants/stylesBreakpoints';
import useMedia from 'hooks/use-media';
import theme from './card-information.scss';

const classNames = ClassNames(theme);

export const CardInformationMobileCTA: FunctionComponent = () => {
  const isPhone = useMedia(USE_MEDIA_BREAKPOINTS.maxWidthString.xxSmall);
  if (!isPhone) {
    return null;
  }
  return ReactDOM.createPortal(
    <div className={classNames('mobile-cta')}>
      <BuyLootPacksPicker buttonId="redeem-form-button" icon="treasure" />
    </div>,
    document.body,
  );
};

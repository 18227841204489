import { RedemptionRedeemedField, UserCard } from 'model/indexTS';
import { useTranslation } from 'utils/translation';
import trackEventManager from 'utils/event-manager/trackEventManager';
import { usePageSelectors } from 'services/page';
import { useUserSetsSelectors } from 'services/user-sets';
import { useSetsSelectors } from 'services/sets';
import { useRedemptionActions } from 'services/redemption';
import { TestOnboardingCardParams, useTestAlertsActions } from 'services/test-alert';
import { useReactAlert } from 'components/react-alert-provider';
import { testCardParamsMapping } from 'components/quick-test-card';
import redeemFieldFactory from 'factories/redeem-field.factory';
import { ActionError, ActionSuccess } from 'types/indexTS';
import { RedeemCardForm, RedeemCardFormData } from './RedeemCardForm';

export interface RedeemFormProps {
  card: UserCard;
  isPreview?: boolean;
  isCooldownActivated?: boolean;
  isFragmented?: boolean;
  isPredefinedCard?: boolean;
  isMobile?: boolean;
  showDestroy: boolean;
  onDestroy: (count: number) => void;
  disenchantingReward?: number;
}

export const RedeemForm = ({
  card,
  isPreview = false,
  isCooldownActivated = false,
  isFragmented = false,
  isPredefinedCard = false,
  showDestroy,
  disenchantingReward,
  isMobile = false,
  onDestroy,
}: RedeemFormProps): JSX.Element => {
  const { t } = useTranslation('card');
  const { showSuccess, showError } = useReactAlert();
  const { redeemCard, testRedemption } = useRedemptionActions();
  const { testCard } = useTestAlertsActions();
  const { selectedUserSet } = useUserSetsSelectors();
  const { selectedSetId } = useSetsSelectors();
  const {
    page: { slug },
  } = usePageSelectors();

  const executeAction = (formData: RedeemCardFormData): Promise<ActionSuccess<string> | ActionError<string>> => {
    if (isPredefinedCard) {
      const params = testCardParamsMapping(card) as TestOnboardingCardParams;
      return testCard(slug, params);
    }

    let redeemFields: RedemptionRedeemedField[] = [];
    if (card.redeemFields && card.redeemFields.length > 0) {
      redeemFields = card.redeemFields.map(field => {
        const { name } = field;
        return { ...field, value: formData[name] };
      });
    }

    if (isPreview || !selectedUserSet?.collectionId) {
      return testRedemption(selectedSetId, card._id, redeemFields);
    }

    if (formData.playAsAnonymous) {
      redeemFields.push(redeemFieldFactory.createAnonymousRedeemedField());
    }

    return redeemCard(selectedUserSet.collectionId, card._id, redeemFields, selectedUserSet._id);
  };

  const onSubmit = (formData: RedeemCardFormData) => {
    return executeAction(formData).then(response => {
      if (!response.error) {
        showSuccess(t('card:cardPlayed'));
        trackEventManager.cardRedeemed();
        return;
      }

      showError(response.payload.message);
    });
  };

  return (
    <RedeemCardForm
      card={card}
      collectionPaused={Boolean(selectedUserSet?.paused)}
      redeemFields={card.redeemFields}
      onSubmit={onSubmit}
      isCooldownActivated={isCooldownActivated}
      isFragmented={isFragmented}
      isPreview={isPreview || isPredefinedCard}
      showDestroy={showDestroy}
      isPredefinedCard={isPredefinedCard}
      isMobile={isMobile}
      disenchantingReward={disenchantingReward}
      onDestroy={onDestroy}
      t={t}
    />
  );
};

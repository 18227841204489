import type { OwnerCard, RedeemField, RedemptionRedeemedField } from 'model/indexTS';
import type { TestOnboardingCardParams } from 'services/test-alert';
import { RewardFieldType } from 'model/indexTS';
import { isOfType } from 'types/indexTS';

export type TestCardParamsMapperArgs = Pick<OwnerCard, 'rewardFields'> & {
  redeemFields?: (RedeemField | RedemptionRedeemedField)[];
  name?: string;
  setId?: string;
  _id?: string;
};

const cardHasAlert = (card: TestCardParamsMapperArgs | null | undefined): boolean => {
  if (!card) {
    return false;
  }

  const { rewardFields } = card;

  if (!rewardFields || rewardFields.length === 0) {
    return false;
  }

  return rewardFields.map(field => field.type).indexOf(RewardFieldType.ALERT) > -1;
};

export default (card: TestCardParamsMapperArgs): TestOnboardingCardParams | null => {
  const { rewardFields, redeemFields } = card;
  if (!rewardFields || rewardFields.length === 0 || !cardHasAlert(card)) {
    return null;
  }

  const parameters: TestOnboardingCardParams = {
    card: {
      rewardFields,
      cardSetId: card._id,
      cardName: card.name,
      setId: card.setId,
    },
    redeemFields: [],
  };

  if (!redeemFields || redeemFields.length === 0) {
    return parameters;
  }

  parameters.redeemFields = redeemFields.map(redeemField => ({
    ...redeemField,
    value: isOfType<RedemptionRedeemedField>(redeemField, 'value') ? redeemField.value : 'test',
  }));

  return parameters;
};

import { createSelector } from 'reselect';
import type { CraftingModeStoreState, CraftingModeState } from './types';

const disenchantState = (state: CraftingModeStoreState): CraftingModeState => state.craftingMode;

const dustBalance = createSelector<CraftingModeStoreState, CraftingModeState, number>(
  [disenchantState],
  (disenchantStateInfo: CraftingModeState): number => disenchantStateInfo.dustBalance || 0,
);

export const craftingModeSelectors = {
  dustBalance,
};

import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FetchSubscriptions,
  PlayAlert,
  CreateSubscriptionOrder,
  ExecuteSubscriptionOrder,
  CancelSubscription,
  GetSubscriptionPlanPaypal,
  ApproveSubscriptionPlanPaypal,
  GetSubscriptionOutput,
  claimSubscriptionPack,
} from './types';
import { subscriptionsActions } from './actions';
import { subscriptionSelectors } from './selector';

export interface UseSubscriptionsActions {
  fetchSubscriptions: FetchSubscriptions;
  playAlert: PlayAlert;
  createSubscriptionOrder: CreateSubscriptionOrder;
  executeSubscriptionOrder: ExecuteSubscriptionOrder;
  cancelSubscription: CancelSubscription;
  getSubscriptionPlanPaypal: GetSubscriptionPlanPaypal;
  approveSubscriptionPlanPaypal: ApproveSubscriptionPlanPaypal;
  claimSubscriptionPack: claimSubscriptionPack;
}

export const useSubscriptionsActions = (): UseSubscriptionsActions => {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      fetchSubscriptions: slug => dispatch(subscriptionsActions.fetchSubscriptions(slug)),
      playAlert: (slug, alertId) => dispatch(subscriptionsActions.playAlert(slug, alertId)),
      createSubscriptionOrder: (slug, stripeToken) =>
        dispatch(subscriptionsActions.createSubscriptionOrder(slug, stripeToken)),
      executeSubscriptionOrder: (slug, paymentId) =>
        dispatch(subscriptionsActions.executeSubscriptionOrder(slug, paymentId)),
      cancelSubscription: subscriptionId => dispatch(subscriptionsActions.cancelSubscription(subscriptionId)),
      getSubscriptionPlanPaypal: slug => dispatch(subscriptionsActions.getSubscriptionPlanPaypal(slug)),
      approveSubscriptionPlanPaypal: (slug, data) =>
        dispatch(subscriptionsActions.approveSubscriptionPlanPaypal(slug, data)),
      claimSubscriptionPack: (slug: string, setId: string, amount: number) =>
        dispatch(subscriptionsActions.claimSubscriptionPack(slug, setId, amount)),
    }),
    [dispatch],
  );
};

interface UseSubscriptionsSelectors {
  subscription: GetSubscriptionOutput;
  isSubscribed: boolean;
  paypalClientId: string | undefined;
  paypalPlanId: string | undefined;
}

export const useSubscriptionsSelectors = (): UseSubscriptionsSelectors => {
  const subscription = useSelector(state => subscriptionSelectors.getSubscription(state));
  const isSubscribed = useSelector(state => subscriptionSelectors.isSubscribed(state));
  const paypalPlanId = useSelector(state => subscriptionSelectors.getPaypalPlanId(state));
  const paypalClientId = useSelector(state => subscriptionSelectors.getPaypalClientId(state));

  return {
    subscription,
    isSubscribed,
    paypalClientId,
    paypalPlanId,
  };
};

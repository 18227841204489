import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InvalidateUserSets } from './types';
import { dataConciliatorSelectors } from './selectors';
import { dataConciliatorActions } from './actions';

export interface UseDataConciliatorSelectors {
  invalidateSetOrUserSet: boolean;
  reconcilePage: boolean;
  reconcileSets: boolean;
  reconcileUserSets: boolean;
  reconcileAuctions: boolean;
  reconcileUser: boolean;
}

export interface UseDataConciliatorActions {
  invalidateUserSets: InvalidateUserSets;
}

export const useDataConciliatorActions = (): UseDataConciliatorActions => {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      invalidateUserSets: () => dispatch(dataConciliatorActions.invalidateUserSets()),
    }),
    [dispatch],
  );
};

export const useDataConciliatorSelectors = (): UseDataConciliatorSelectors => {
  return {
    invalidateSetOrUserSet: useSelector(dataConciliatorSelectors.invalidateSetOrUserSet),
    reconcilePage: useSelector(dataConciliatorSelectors.reconcilePage),
    reconcileSets: useSelector(dataConciliatorSelectors.reconcileSets),
    reconcileUserSets: useSelector(dataConciliatorSelectors.reconcileUserSets),
    reconcileAuctions: useSelector(dataConciliatorSelectors.reconcileAuctions),
    reconcileUser: useSelector(dataConciliatorSelectors.reconcileUser),
  };
};

import { Rarity } from '@streamloots/streamloots-types';
import { tFunction, useTranslation } from 'utils/translation';
import { CardRarity } from 'components/card';
import CardStatusIcon from './components/card-status-icon';

const getLimitedTexts = ({
  cardsLimitedRemaining,
  totalLimit,
  isPreview,
  t,
}: {
  cardsLimitedRemaining?: number;
  totalLimit?: number;
  isPreview?: boolean;
  t: tFunction;
}) => {
  const getLimitedTooltip = () => {
    if (isPreview && totalLimit) {
      return t('statuses.limitedRemainingTooltip', {
        count: totalLimit,
      });
    }

    if (cardsLimitedRemaining && cardsLimitedRemaining > 0) {
      return t('statuses.limitedRemainingTooltip', {
        count: cardsLimitedRemaining,
      });
    }

    return t('statuses.limitedTooltip');
  };
  const getLimitedText = () => {
    if (isPreview) {
      return t('statuses.limitedCardPreview');
    }

    if (cardsLimitedRemaining && cardsLimitedRemaining > 0) {
      return t('statuses.limitedRemaining', { count: cardsLimitedRemaining });
    }

    return t('statuses.limited');
  };
  return {
    tooltip: getLimitedTooltip(),
    text: getLimitedText(),
  };
};

const getFragmentedTexts = ({
  availableFragments,
  totalFragments,
  isPreview,
  t,
}: {
  availableFragments?: number;
  totalFragments?: number;
  isPreview?: boolean;
  t: tFunction;
}) => {
  const getFragmentedTooltip = () => {
    if (availableFragments === totalFragments) {
      return t('statuses.fragmentsCompletedTooltip');
    }
    return t('statuses.fragmentsTooltip', { fragments: totalFragments });
  };
  const getFragmentedText = () => {
    if (isPreview) {
      return t('statuses.fragmentsPreview');
    }
    return t('statuses.fragments', { availableFragments, totalFragments });
  };
  return {
    tooltip: getFragmentedTooltip(),
    text: getFragmentedText(),
  };
};

interface CardInformationStatusProps {
  craftable: boolean;
  cooldownTime?: string;
  isObtainable: boolean;
  rarity: Rarity;
  totalLimit?: number;
  limited?: boolean;
  cardsLimitedRemaining?: number;
  isFragmented?: boolean;
  availableFragments?: number;
  redeemableCards: number;
  totalFragments?: number;
  isPreview?: boolean;
  paid?: boolean;
}

export const CardInformationStatus = ({
  craftable,
  cooldownTime,
  limited,
  cardsLimitedRemaining,
  isObtainable,
  isFragmented,
  availableFragments,
  totalFragments,
  rarity,
  redeemableCards,
  isPreview,
  totalLimit,
  paid,
}: CardInformationStatusProps) => {
  const { t } = useTranslation('card');
  const limitedTexts = getLimitedTexts({
    cardsLimitedRemaining,
    totalLimit,
    isPreview,
    t,
  });

  const fragmentedTexts = getFragmentedTexts({
    availableFragments,
    totalFragments,
    isPreview,
    t,
  });

  return (
    <>
      {!isPreview && (
        <CardStatusIcon
          redeemableCards={redeemableCards}
          tooltip={
            redeemableCards === 0
              ? t('statuses.noRedeemableCardsTooltip')
              : t('statuses.redeemableCardsTooltip', { count: redeemableCards })
          }
          type={CardStatusIcon.TYPES.REDEEMABLE}
        >
          {t('statuses.redeemableCards', { count: redeemableCards })}
        </CardStatusIcon>
      )}

      <CardStatusIcon tooltip={t('statuses.rarityTooltip')} type={CardStatusIcon.TYPES.RARITY}>
        <CardRarity rarity={rarity} component="strong" />
      </CardStatusIcon>
      {!isPreview && !isObtainable && (
        <CardStatusIcon tooltip={t('statuses.notAvailableTooltip')} type={CardStatusIcon.TYPES.NOT_AVAILABLE}>
          {t('statuses.notAvailable')}
        </CardStatusIcon>
      )}
      {craftable && (
        <CardStatusIcon tooltip={t('statuses.craftableTooltip')} type={CardStatusIcon.TYPES.CRAFTABLE}>
          {t('statuses.craftable')}
        </CardStatusIcon>
      )}

      {cooldownTime && (
        <CardStatusIcon tooltip={t('statuses.cooldownTooltip')} type={CardStatusIcon.TYPES.COOLDOWN}>
          {t('statuses.cooldown', { cooldownTime })}
        </CardStatusIcon>
      )}
      {limited && (
        <CardStatusIcon tooltip={limitedTexts.tooltip} type={CardStatusIcon.TYPES.LIMITED}>
          {limitedTexts.text}
        </CardStatusIcon>
      )}
      {isFragmented && (
        <CardStatusIcon tooltip={fragmentedTexts.tooltip} type={CardStatusIcon.TYPES.FRAGMENTED}>
          {fragmentedTexts.text}
        </CardStatusIcon>
      )}
      {!isPreview && paid && (
        <CardStatusIcon tooltip={t('card:statuses.paidTooltip')} type={CardStatusIcon.TYPES.PAID}>
          {t('card:statuses.paid')}
        </CardStatusIcon>
      )}
    </>
  );
};

export enum RedeemFieldType {
  TEXT = 'INPUT#TEXT',
  TEXTAREA = 'TEXTAREA',
  HIDDEN = 'INPUT#HIDDEN',
}

export interface RedeemField {
  label: string;
  name: string;
  required?: boolean;
  type: RedeemFieldType;
}

import ClassNames from '@streamloots/classnames';
import OpenAlertButton from 'components/open-alert-tester';
import theme from '../../card-information.scss';

const classNames = ClassNames(theme);

interface CardInformationImageProps {
  image: string;
  title: string;
  isPreview?: boolean;
}

export const CardInformationImage = ({ image, title, isPreview }: CardInformationImageProps): JSX.Element => {
  return (
    <>
      <img src={image} alt={title} className={classNames('cardinfo__image')} />
      {isPreview && (
        <div className="margin-bottom align-center">
          <OpenAlertButton />
        </div>
      )}
    </>
  );
};

import _ from 'lodash';
import { buildCleaner } from 'lodash-clean';
import { Rarity } from '@streamloots/streamloots-types';
import { RewardFieldType, RewardField } from 'model/rewardFieldTS';
import type {
  PublicPredefinedCard,
  PUBLIC_PREDEFINED_CARD_RESPONSE,
  PredefinedCardFrameValue,
} from 'model/predefined-cardsTS';

const options = {
  isNull: _.noop,
  isUndefined: _.noop,
};
const clean = buildCleaner(options);

export const getSelectedFrame = (
  frames: PredefinedCardFrameValue[],
  frameId: string,
): PredefinedCardFrameValue | null => {
  return _.find(frames, frameItem => frameItem.frameId === frameId) || null;
};

const getRewardFields = (rewardFields: RewardField[], useFrameAsReward: boolean, imageUrl: string) => {
  if (!useFrameAsReward) {
    return rewardFields;
  }

  return rewardFields.map(field => {
    if (field.type === RewardFieldType.ALERT) {
      return {
        ...field,
        imageUrl,
      };
    }
    return field;
  });
};

export const normalizeToPublicCard = (
  predefinedCard: PUBLIC_PREDEFINED_CARD_RESPONSE,
  frameId: string,
): PublicPredefinedCard | null => {
  try {
    const {
      _id,
      actionType,
      autocomplete,
      description,
      descriptionDetailed,
      dropLimit,
      dropLimited,
      fragmented,
      fragments,
      frames,
      name,
      order,
      rarity,
      rarityCardProbability,
      redemptionLimit,
      redeemFields,
      redemptionSuccessMessage,
      rewardFields,
      useFrameAsReward,
      addedToSetCards,
      paid,
      duration,
      integrations,
    } = predefinedCard;
    let redemptionLimitAsDuration;

    /**
     * Why am I checking the duration is highest than 0?
     * This check is to avoid break the onboarding with this edge case.
     * I could remove the last condition, because JS understand 0 as falsy value, but as I stated, duration is a correct value on timer context and
     * I prefer to be explicit in this case.
     */
    if (redemptionLimit && duration && duration > 0) {
      const { timeFrameSeconds } = redemptionLimit?.configuration;
      if (timeFrameSeconds && timeFrameSeconds < duration) {
        redemptionLimitAsDuration = {
          configuration: {
            timeFrameSeconds: duration,
          },
        };
      }
    }

    if (!redemptionLimit && duration && duration > 0) {
      redemptionLimitAsDuration = {
        configuration: {
          timeFrameSeconds: duration,
        },
      };
    }

    const frame = getSelectedFrame(frames, frameId);

    if (frame) {
      const { imageUrl } = frame;

      return {
        _id,
        actionType,
        addedToSetCards,
        autocomplete,
        description,
        descriptionDetailed,
        dropLimit,
        dropLimited,
        duration,
        fragmented,
        fragments,
        frames,
        imageUrl,
        integrations: clean(integrations),
        name,
        order,
        paid: paid ?? false,
        rarity,
        rarityCardProbability,
        redeemable: true,
        redeemFields,
        redemptionLimit: redemptionLimitAsDuration || redemptionLimit,
        redemptionSuccessMessage,
        rewardFields: getRewardFields(rewardFields, useFrameAsReward, imageUrl),
      };
    }

    return null;
  } catch (e) {
    return null;
  }
};

export const normalizePredefinedCards = (
  cards: PUBLIC_PREDEFINED_CARD_RESPONSE[],
  frameId: string,
): PublicPredefinedCard[] => {
  const normalizedCards: PublicPredefinedCard[] = [];

  cards.forEach(card => {
    const publicPredefinedCard = normalizeToPublicCard(card, frameId);
    if (publicPredefinedCard !== null) {
      normalizedCards.push(publicPredefinedCard);
    }
  });

  return _.sortBy(normalizedCards, cardTemplate => {
    switch (cardTemplate.rarity) {
      case Rarity.LEGENDARY:
        return 0;
      case Rarity.EPIC:
        return 1;
      case Rarity.RARE:
        return 2;
      default:
        return 3;
    }
  });
};

import { FunctionComponent } from 'react';
import ClassNames from '@streamloots/classnames';
import { UserCard } from 'model/indexTS';
import { TranslateInterface, withTranslation } from 'utils/translation';
import { ButtonIcon } from 'ui-library/button';
import { DisenchantResourceSymbol } from 'components/disenchant-resources';
import DestroyConfirmationDialog from '../destroy-confirmation-dialog';
import theme from './destroy-button-dialog.scss';

const classNames = ClassNames(theme);

interface DestroyButtonProps extends TranslateInterface, Pick<UserCard, 'disenchantingReward' | 'fragmented'> {
  isMobile: boolean;
  maxDestroyable: number;
  onDestroy: (numCardsToDestroy: number) => void;
}

type OwnProps = Omit<DestroyButtonProps, 't'>;

const getTranslationKey = (fragmented, k) =>
  fragmented ? `collection:destroy.fragment.${k}` : `collection:destroy.card.${k}`;

const DestroyButtonBase = ({
  disenchantingReward,
  fragmented,
  isMobile,
  maxDestroyable,
  t,
  onDestroy,
}: DestroyButtonProps) => {
  const buttonText = isMobile ? 'buttonTextMobile' : 'buttonText';
  return (
    <DestroyConfirmationDialog
      renderButton={({ onShow }) => (
        <ButtonIcon
          className={classNames('destroy-button')}
          iconClass={classNames('destroy-button__icon')}
          asLink
          icon="fire"
          onClick={onShow}
        >
          {t(getTranslationKey(fragmented, buttonText))} +{disenchantingReward}
          <DisenchantResourceSymbol className={classNames('destroy-button--symbol')} />
        </ButtonIcon>
      )}
      onConfirmClick={onDestroy}
      title={t(getTranslationKey(fragmented, 'dialogTitle'))}
      confirmKey={getTranslationKey(fragmented, 'dialogButton')}
      maxDestroyable={maxDestroyable}
      messageKey={getTranslationKey(fragmented, 'dialogText')}
      labelKey={getTranslationKey(fragmented, 'inputLabel')}
      availableCardsKey={getTranslationKey(fragmented, 'availableCards')}
      disenchantingReward={disenchantingReward}
    />
  );
};

export const DestroyCardButton = withTranslation('collection')(DestroyButtonBase) as FunctionComponent<OwnProps>;

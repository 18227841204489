import { Rarity } from '@streamloots/streamloots-types';
import { RedeemFieldType } from 'model/indexTS';
import type { DropdownOption } from 'ui-library/form-elements';
import { tFunction } from 'utils/translation';
import theme from './cardfieldbuilder.scss';

const getTextOrUnknown = (t: tFunction, prefix: string, value?: string) => {
  const key = !value ? 'card:unknown' : `card:${prefix}.${value.toLowerCase()}`;
  return t(key);
};

const getClass = (value?: string) => {
  if (!value) {
    return '';
  }
  return theme[`rarity__${value.toLowerCase()}`];
};

const getCardRaritiesWrapperClass = (): string => {
  return theme.rarities;
};

const getRarityClass = (rarity: Rarity): string => getClass(rarity);

const getRarityText = (rarity: Rarity, t: tFunction): string => getTextOrUnknown(t, 'rarity', rarity);

const getRedeemFieldType = (fieldType: RedeemFieldType, t: tFunction): string => {
  switch (fieldType) {
    case RedeemFieldType.TEXT:
      return t('card:redeemField.text');
    case RedeemFieldType.TEXTAREA:
      return t('card:redeemField.textarea');
    default:
      return getTextOrUnknown(t, 'redeemField');
  }
};

interface GetCardRarityInfo extends DropdownOption<string> {
  className: string;
  text: string;
  rarity: Rarity;
}
const getCardRarityInfo = (rarity: Rarity, t: tFunction): GetCardRarityInfo => ({
  text: getRarityText(rarity, t),
  className: getRarityClass(rarity),
  rarity,
  label: getRarityText(rarity, t),
  value: rarity,
});

const getRedeemFieldTypeInfo = (fieldType: RedeemFieldType, t: tFunction): DropdownOption => ({
  label: getRedeemFieldType(fieldType, t),
  value: fieldType,
});

const getCardRarities = (t: tFunction): GetCardRarityInfo[] =>
  Object.values(Rarity).map(rarity => getCardRarityInfo(rarity, t));

const getRedeemFieldTypes = (t: tFunction): DropdownOption[] =>
  Object.values(RedeemFieldType).map(type => getRedeemFieldTypeInfo(type, t));

export default {
  getRarityText,
  getRedeemFieldType,
  getCardRarityInfo,
  getCardRarities,
  getCardRaritiesWrapperClass,
  getRarityClass,
  getRedeemFieldTypes,
};

import { createSelector } from 'reselect';
import type { Cooldown, UserCooldownStatus } from 'model/indexTS';
import type { CooldownStoreState, CooldownState } from './types';

const cooldownState = (state: CooldownStoreState): CooldownState => state.cooldown;

const countdown = createSelector(
  [cooldownState],
  (cooldownStateInfo: CooldownState): string | undefined => cooldownStateInfo.countdown,
);

const cooldown = createSelector(
  [cooldownState],
  (cooldownStateInfo: CooldownState): Cooldown | undefined => cooldownStateInfo.cooldown,
);

const userCooldownStatus = createSelector(
  [cooldownState],
  (cooldownStateInfo: CooldownState): UserCooldownStatus | undefined => cooldownStateInfo.userCooldownStatus,
);

const userCooldownResetAt = createSelector(
  [cooldownState],
  (cooldownStateInfo: CooldownState): Date | undefined => cooldownStateInfo.userCooldownResetAt,
);

export const cooldownSelectors = {
  cooldown,
  countdown,
  userCooldownStatus,
  userCooldownResetAt,
};

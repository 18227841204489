import type { ComponentType, ReactHTML } from 'react';
import { Rarity } from '@streamloots/streamloots-types';
import ClassNames from '@streamloots/classnames';
import { tFunction, TranslateInterface, withTranslation } from 'utils/translation';
import cardFieldBuilder from './cardFieldBuilder';

const classNames = ClassNames({});

export type CardTypeComponentProps = {
  component?: ComponentType | keyof ReactHTML;
  className?: string;
  count?: number;
  rarity: Rarity;
};

type ExtendedProps = CardTypeComponentProps & TranslateInterface;

const getText = (t: tFunction, rarity: Rarity, count?: number) => {
  if (!count) {
    return cardFieldBuilder.getRarityText(rarity, t);
  }
  return t(`card:${rarity.toLowerCase()}Count`, { count });
};

const CardRarityComponent = ({ className = '', count, component: Component = 'div', rarity, t }: ExtendedProps) => {
  const rarityClass = cardFieldBuilder.getRarityClass(rarity);

  return (
    <Component
      className={classNames({
        [rarityClass]: rarityClass,
        [className]: className,
      })}
    >
      {getText(t, rarity, count)}
    </Component>
  );
};

export default withTranslation('card')(CardRarityComponent);

import { createSelector } from 'reselect';
import type { PublicPageCardFilters, UserCard, UserCardsById } from 'model/indexTS';
import type { UserSetCardsState, UserSetCardsStateBySetId, UserSetCardsStoreState } from './types';
import { filterCollectionCards } from './filterCardsController';

const userSetCardsState = (state: UserSetCardsStoreState): UserSetCardsState => state.userSetCards;

const userSetCardsStateByCollectionId = createSelector(
  [userSetCardsState, (_state, setId) => setId],
  (userSetCardsStateInfo: UserSetCardsState, setId: string): UserSetCardsStateBySetId =>
    userSetCardsStateInfo[setId] || {},
);

const achievedCardsCount = createSelector(
  [userSetCardsStateByCollectionId],
  (userSetCardsStateByCollectionIdInfo: UserSetCardsStateBySetId): number =>
    userSetCardsStateByCollectionIdInfo.achievedCards || 0,
);

const cards = createSelector(
  [userSetCardsStateByCollectionId],
  (userSetCardsStateByCollectionIdInfo: UserSetCardsStateBySetId): Array<UserCard> | undefined =>
    userSetCardsStateByCollectionIdInfo.cards,
);

const filteredCards = createSelector(
  [userSetCardsStateByCollectionId],
  (userSetCardsStateByCollectionIdInfo: UserSetCardsStateBySetId): Array<UserCard> => {
    const { cards: collectionCards = [], filters } = userSetCardsStateByCollectionIdInfo;

    return filterCollectionCards(collectionCards, filters);
  },
);

const filters = createSelector(
  [userSetCardsStateByCollectionId],
  (userSetCardsStateByCollectionIdInfo: UserSetCardsStateBySetId): PublicPageCardFilters =>
    userSetCardsStateByCollectionIdInfo.filters,
);

const cardsById = createSelector(
  [userSetCardsStateByCollectionId],
  (userSetCardsStateByCollectionIdInfo: UserSetCardsStateBySetId): UserCardsById =>
    userSetCardsStateByCollectionIdInfo.cardsById || {},
);

const card = createSelector(
  [cardsById, (_state, setId) => setId, (_state, _setId, cardId) => cardId],
  (cardsByIdInfo: UserCardsById, _setId: string, cardId: string): UserCard => cardsByIdInfo[cardId] || null,
);

const cardsCount = createSelector([cards], (cardsInfo: Array<UserCard> | undefined) => {
  const setCards = cardsInfo || [];
  return setCards.length;
});

export const userSetCardsSelectors = {
  userSetCardsStateByCollectionId,
  achievedCardsCount,
  card,
  cards,
  cardsCount,
  cardsById,
  filteredCards,
  filters,
};

export const craftingModeErrorMapping = {
  SET_CARD_NOT_EXISTS: 'disenchant:error.cardNotExists',
  SET_CRAFTABLE_CARDS_DISABLED: 'disenchant:error.craftingModeDisabled',
  COLLECTION_SET_CARD_TYPE_HAS_NOT_ENOUGH_CARDS: 'disenchant:error.notEnoughCardsToDisenchant',
  SET_CARD_NOT_CRAFTABLE: 'disenchant:error.cardNotCraftable',
  USER_HAS_NOT_ENOUGH_DUST: 'disenchant:error.notEnoughDust',
  CRAFT_CARD_FAILED: 'disenchant:error.craftFailed',
  COLLECTION_NOT_EXISTS: 'disenchant:error.collectionNotExists',
  SET_CARD_DROP_LIMIT_REACHED: 'disenchant:error.dropLimitReached',
};

import { useEffect } from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import ClassNames from '@streamloots/classnames';
import { Checkbox } from 'ui-library/forms';
import { useSubscriptionsSelectors } from 'services/subscriptions';
import type { UserCard, RedeemField } from 'model/indexTS';
import { TranslateInterface } from 'utils/translation';
import { PageModulesChecker } from 'components/page-modules-checker';
import { PagesModules } from '@streamloots/streamloots-types';
import { OnlySubsBadge } from 'components/only-subs-badge';
import { Form } from 'components/forms';
import { TooltipHelpIndicatorIcon } from 'components/help-tooltip';
import { DestroyCardButton } from '../destroy-button-dialog';
import { RedeemedFieldInput } from './RedeemFieldInput';
import theme from './redeem-form.scss';

const classNames = ClassNames(theme);

export type RedeemCardFormData = Record<string, string | boolean>;

interface OwnProps extends TranslateInterface {
  card: UserCard;
  redeemFields?: RedeemField[];
  collectionPaused: boolean;
  isCooldownActivated: boolean;
  isFragmented: boolean;
  isPreview: boolean;
  isPredefinedCard?: boolean;
  showDestroy: boolean;
  isMobile: boolean;
  onDestroy: (count: number) => void;
  disenchantingReward?: number;
}

interface RedeemedFormProps extends OwnProps, InjectedFormProps<RedeemCardFormData, OwnProps> {}

const RedeemedFormInternal = ({
  redeemFields = [],
  collectionPaused,
  isPreview,
  card,
  isCooldownActivated,
  isFragmented,
  t,
  submitSucceeded,
  initialize,
  isMobile,
  showDestroy,
  onDestroy,
  disenchantingReward,
  isPredefinedCard,
  ...rest
}: RedeemedFormProps) => {
  const { isSubscribed } = useSubscriptionsSelectors();
  const {
    count: { availableFragments, redeemable: redeemableCards } = {
      availableFragments: 0,
      redeemable: 0,
    },
  } = card;
  const hasTotalFragments = isFragmented && availableFragments < (card.fragments || 0);
  const isDisabled = !isPreview && (collectionPaused || isCooldownActivated || hasTotalFragments);

  useEffect(() => {
    if (submitSucceeded) {
      initialize({});
    }
  }, [initialize, submitSucceeded]);

  const shouldShowDestroyButton = showDestroy && isMobile;

  return (
    <Form
      {...rest}
      disabled={isDisabled}
      buttonIcon="cards"
      button={t('card:redeemCards')}
      renderToolbox={({ submitButton }) => {
        return (
          <div
            className={classNames({
              'margin-top': true,
              'align-right': !shouldShowDestroyButton,
              'redeem-form__buttons': shouldShowDestroyButton,
            })}
          >
            {shouldShowDestroyButton && disenchantingReward && (
              <DestroyCardButton
                onDestroy={onDestroy}
                fragmented={card.fragmented}
                isMobile={isMobile}
                disenchantingReward={disenchantingReward}
                maxDestroyable={card.fragmented ? availableFragments : redeemableCards}
              />
            )}
            {submitButton}
          </div>
        );
      }}
    >
      {redeemFields &&
        redeemFields.map(field => {
          return <RedeemedFieldInput key={field.name} {...field} />;
        })}

      {!isPredefinedCard && (
        <PageModulesChecker modulesRequired={PagesModules.Subscriptions}>
          <div className="flex gap-3 items-center justify-end">
            <OnlySubsBadge />
            <Checkbox
              id="playAsAnonymous"
              reverse
              className="pt-0"
              disabled={!isSubscribed}
              label={
                <>
                  <TooltipHelpIndicatorIcon
                    tooltip={t('card:playAsAnonymousHelp')}
                    tooltipPosition="top"
                    className="ml-0"
                    asLink
                  />
                  {t('card:playAsAnonymous')}
                </>
              }
              name="playAsAnonymous"
            />
          </div>
        </PageModulesChecker>
      )}
    </Form>
  );
};

export const RedeemCardForm = reduxForm<RedeemCardFormData, OwnProps>({
  form: 'redeemForm',
})(RedeemedFormInternal);

import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useOverloadedContentActions } from 'services/overloaded-content';
import { urls } from 'services/routing';
import { useSubscriptionsSelectors } from 'services/subscriptions';
import trackEventManager from 'utils/event-manager';
import { SubscriptionCheckoutDialog } from './SubscriptionCheckoutDialog';

export const useSubscriptionCheckoutOpener = () => {
  const { isSubscribed } = useSubscriptionsSelectors();
  const { openOverloadedContent } = useOverloadedContentActions();
  const history = useHistory();

  const openSubscriptionCheckout = useCallback(
    (buttonId: string, alertName?: string) => {
      trackEventManager.subscriptionPurchasedClick(
        {
          location: `${buttonId}${!alertName ? '' : `_${alertName}`}`,
        },
        true,
      );

      history.replace({
        pathname: urls().USER_PAGE_SUBSCRIPTION,
      });

      openOverloadedContent({
        component: SubscriptionCheckoutDialog,
        wrapperProps: {
          type: 'large',
          opacity: true,
          preventCloseOnOutsideClick: true,
        },
      });
    },
    [history, openOverloadedContent],
  );

  return { openSubscriptionCheckout, isSubscribed };
};

import { Rarity } from '@streamloots/streamloots-types';

export enum PublicPageCardSelectableFilters {
  AVAILABLE = 'available',
  NOT_ACHIEVED = 'notAchieved',
  LIMITED = 'limited',
}

export interface PublicPageCardFilters {
  options: Array<PublicPageCardSelectableFilters>;
  searchString?: string;
  rarities: Array<Rarity>;
}

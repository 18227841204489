import ClassNames from '@streamloots/classnames';
import { usePageSelectors } from 'services/page';
import Tooltip from 'ui-library/tooltip';
import { useTranslation } from 'react-i18next';
import { useCultureSelectors } from 'services/culture';
import { LoveFormatter } from 'domain/love-formatter';
import theme from './disenchant-resources.scss';

const classNames = ClassNames(theme);

interface DisenchantResourceSymbolProps {
  className?: string;
  includeBalance?: boolean;
  hiddenIfNoResources?: boolean;
  formattedBalance?: boolean;
}

export const DisenchantResourceSymbol = ({
  className = '',
  includeBalance,
  formattedBalance,
  hiddenIfNoResources,
}: DisenchantResourceSymbolProps): JSX.Element | null => {
  const { language } = useCultureSelectors();
  const { t } = useTranslation('collection');
  const {
    page: { customization, dustBalance = 0 },
  } = usePageSelectors();
  const dustImageUrl = customization?.dustImageUrl ?? '';
  const formatBalance = formattedBalance || dustBalance >= 10000;

  if (hiddenIfNoResources && !dustBalance) {
    return null;
  }

  if (includeBalance) {
    return (
      <Tooltip tooltip={t('collection:craftingModeHelp')}>
        <div
          className={classNames({
            'resources-balance': true,
            [className]: Boolean(className),
          })}
        >
          <span title={!formatBalance ? dustBalance.toString() : undefined}>
            {!formatBalance ? dustBalance : LoveFormatter.format(dustBalance, language)}
          </span>
          <img className={classNames('resources-symbol')} src={dustImageUrl} alt="Resources" />
        </div>
      </Tooltip>
    );
  }

  return (
    <img
      className={classNames({
        'resources-symbol': true,
        [className]: Boolean(className),
      })}
      src={dustImageUrl}
      alt="Resources"
    />
  );
};

import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import { achievementsSideEffects } from 'services/sideEffects';
import type {
  CardCraftedActionSuccess,
  CardCraftedActionError,
  CardDisenchantedActionError,
  CardDisenchantedActionSuccess,
} from './types';
import { craftingModeErrorMapping } from './errorMapping';
import { CraftingModeActionTypes } from './actionTypes';

const cardCraftedSuccess = ({ additionalData, data, parameters }): CardCraftedActionSuccess =>
  actionCreator.createAction(CraftingModeActionTypes.CRAFTED, {
    ...data,
    ...parameters,
    setId: additionalData.setId,
  });

const cardCraftedError = ({ additionalData, errorData }): CardCraftedActionError =>
  actionCreator.createErrorAction(CraftingModeActionTypes.CRAFTED, errorData, craftingModeErrorMapping, {
    setId: additionalData.setId,
  });

const craftCards = (setCardId: string, quantity: number, collectionId: string, setId: string) =>
  actionCreator.createAsyncAction({
    additionalData: { setId },
    endpoint: endpoints.CRAFTING.CRAFTINGS,
    errorBinding: cardCraftedError,
    loadingAction: CraftingModeActionTypes.CRAFTING,
    method: 'post',
    parameters: { setCardId, quantity },
    urlParameters: { collectionId },
    successBinding: cardCraftedSuccess,
    successAsyncSideEffects: achievementsSideEffects,
  });

const cardDisenchantedSuccess = ({ additionalData, data, parameters }): CardDisenchantedActionSuccess =>
  actionCreator.createAction(CraftingModeActionTypes.DISENCHANTED, {
    ...data,
    ...parameters,
    setId: additionalData.setId,
  });

const cardsNotDisenchanted = ({ additionalData, errorData }): CardDisenchantedActionError =>
  actionCreator.createErrorAction(CraftingModeActionTypes.DISENCHANTED, errorData, craftingModeErrorMapping, {
    setId: additionalData.setId,
  });

const disenchantCards = (setCardId: string, quantity: number, collectionId: string, setId: string) => {
  return actionCreator.createAsyncAction({
    additionalData: { setId },
    endpoint: endpoints.CRAFTING.DISENCHANT,
    errorBinding: cardsNotDisenchanted,
    loadingAction: CraftingModeActionTypes.DISENCHANTING,
    method: 'post',
    parameters: { setCardId, quantity },
    urlParameters: { collectionId },
    successBinding: cardDisenchantedSuccess,
    successAsyncSideEffects: achievementsSideEffects,
  });
};

export const craftingModeActions = {
  craftCards,
  disenchantCards,
};

import { useTranslation } from 'utils/translation';
import CardStatusIcon from './components/card-status-icon';
import { CountdownWithIcon } from './components/countdown-with-icon';

interface CooldownCountdownProps {
  cooldownDate?: string;
  active: boolean;
  onComplete: () => void;
}

export const CooldownCountdown = ({ cooldownDate, active, onComplete }: CooldownCountdownProps) => {
  const { t } = useTranslation('card');
  if (!active || !cooldownDate) {
    return null;
  }

  return (
    <CountdownWithIcon
      type={CardStatusIcon.TYPES.NEGATIVE_COOLDOWN}
      date={new Date(cooldownDate)}
      label={t('card:cooldown')}
      onComplete={onComplete}
      tooltip={t('card:cooldownTooltip')}
    />
  );
};
